@font-face {
  font-family: 'GeneralSans';
  src: url('../public/fonts/GeneralSans-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'GeneralSans-Medium';
  src: url('../public/fonts/GeneralSans-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'GeneralSans-Bold';
  src: url('../public/fonts/GeneralSans-Bold.otf') format('opentype');
}
